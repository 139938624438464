// data/technicians.js

export const technicians = [
    {
      id: 1,
      name: 'MAUL TECHNOLOGIES',
      description: 'Laptop Dealer & Repairs | Accessories',
      location: { lat: -17.88044939811381, lng: 31.083046252352243 },
      contact: '+263772356441',
      website: 'pending',
      email: 'pending',
    },
    {
      id: 2,
      name: 'NGAATEC PRIVATE LIMITED',
      description: 'Computer repairs and web solutions',
      location: { lat: -17.990950515776856, lng: 31.058356766035658 },
      contact: '+263783827570',
      website: 'https://ngaatec.co.zw',
      email: 'info@ngaatec.co.zw',
    },
    {
      id: 3,
      name: 'CODE TECHNICAL SOLUTIONS',
      description: 'CCTV and Biometrics Solutions',
      location: { lat: -17.990891841313694, lng: 31.058278981978447 },
      contact: '+263774074503',
      website: 'https://codetechnicalsolutions.co.zw',
      email: 'info@codetechnicalsolutions.co.zw',
    },
    {
      id: 4,
      name: 'KAPSTONE',
      description: 'Printing Services',
      location: { lat: -17.991021945531752, lng: 31.058437232301735 },
      contact: '+26377896395',
      website: 'pending',
      email: 'pending',
    },
    {
      id: 5,
      name: 'Maul Technologies',
      description: 'Laptop Dealer & Repair and Accessories',
      location: { lat: -17.832162684190273, lng: 31.048324849136026 },
      contact: '+263772356441',
      website: 'pending...',
      email: 'pending...',
    },
    {
      id: 6,
      name: 'LYNX TECHNOLOGIES',
      description: 'pending info...',
      location: { lat: -17.825751193163665, lng: 31.003721195717862 },  
      contact: 'pending...',
      website: 'pending...',
      email: 'pending...',
    },
    {
      id: 6,
      name: 'Scantop IT Solutions',
      description: 'pending info...',
      location: { lat: -17.819949680282125, lng: 31.0235051683375 },  
      contact: 'pending...',
      website: 'pending...',
      email: 'pending...',
    },
    {
      id: 7,
      name: 'First Pack Services',
      description: 'Computers, Printers, ect (Repair and Sales)',
      location: { lat: -17.890156449546406, lng: 31.06952320407923 },  
      contact: '+2638688002256',
      website: 'https://firstpack.co.zw',
      email: 'enquiries@firstpack.co.zw',
    },
    
    // Add more technicians
  ];
  